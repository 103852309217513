

  
  
  /* Form Input Styling */
  .form-control {
    border-radius: 8px;
    border: 1px solid #ddd;
    transition: border-color 0.2s ease-in-out;
  }
  
  .form-control:focus {
    border-color: #FE3C01; /* Orange border on focus */
    box-shadow: none; /* Remove default shadow */
  }
  
  /* Labels */
  .form-label {
    font-weight: bold;
    color: #555; /* Subtle gray */
    margin-bottom: 5px;
  }
  
  /* Checkbox and Radio Styling */
  .form-check-input {
    margin-right: 10px;
  }
  
  .form-check-label {
    color: #333;
  }
  
  .form-check {
    margin-bottom: 0.5rem; /* Space between checkboxes */
  }
  
  .row {
    margin-bottom: 1rem; /* Space between form rows */
  }
  
  /* Select All Checkbox */
  #selectAll {
    margin-bottom: 10px; /* Space below the 'Select All' option */
  }
  
  /* Save Message Styling */
  .alert {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
  }
  
  .alert-success {
    background-color: #e7f5e9;
    border: 1px solid #c3e6cb;
    color: #155724;
  }
  
  /* Error Messages */
  .invalid-feedback {
    display: block;
    font-size: 0.875rem;
    color: red;
  }
  
  /* Buttons */
  .btn-primary {
    background-color: #FE3C01;
    border: none;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: #d35400;
  }
  
  /* Responsive Styling for Location Checkboxes */
  @media (max-width: 768px) {
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (max-width: 576px) {
    .col-sm-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .card {
      padding: 15px;
    }
  }
  