/* Login.css */

.password-container {
    position: relative;
    width: 100%;
  }
  
  .password-container input {
    padding-right: 40px; /* Space for the eye icon */
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888; /* Subtle icon color */
  }
  
  .password-toggle:hover {
    color: #333; /* Darker color on hover */
  }
  
  