/* PaymentPage.css */

/* General container styling */
.payment-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

/* Section titles */
.payment-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
}

/* Balances section */
.balances {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f7f7f7;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.balance-item {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    color: #333333;
}

.balance-icon {
    margin-right: 8px;
    color: #007bff;
    font-size: 1.5rem;
}

/* Payment history list */
.payment-history {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 20px 0;
    border-top: 1px solid #ccc;
}

.payment-history li {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    font-size: 0.9rem;
    color: #555;
}

/* Package selection row */
.package-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;
}

/* Package cards */
.package-card {
    flex: 1 1 calc(48% - 10px);
    max-width: calc(48% - 10px);
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.package-card.selected {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    border: 2px solid #007bff;
}

.package-content {
    text-align: center;
    width: 100%;
}

.package-icon {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 10px;
}

.package-title {
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 8px;
}

.package-price {
    color: #333;
    font-size: 1rem;
}

/* Discount section */
.discount-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;
}

.discount-input {
    flex: 3;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.discount-button {
    flex: 1;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.discount-button:hover {
    background-color: #0056b3;
}

.discount-info {
    color: #28a745;
    font-weight: bold;
    text-align: center;
}

/* Payment button */
.payment-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 15px;
    width: 100%;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.payment-button:hover {
    background-color: #218838;
}

.payment-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
