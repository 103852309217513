/* Welcome Page Container */
.welcome-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
  background: linear-gradient(135deg, #e0f7fa, #fff9e6, #f9e1ff, #fdfdfe); /* Lighter pastel colors */
  background-size: 400% 400%;
  animation: vibrantGradient 12s ease infinite;
  border-radius: 20px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.05); /* Soft shadow */
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  color: #444; /* Slightly dark but soft text for better contrast */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); /* Lighter shadow */
  transition: transform 0.5s ease;
}

.welcome-container:hover {
  transform: scale(1.02);
}

/* Floating Shapes for Animated Background */
.floating-shapes .shape {
  position: absolute;
  opacity: 0.15;
  animation: floatShapes 10s infinite ease-in-out;
  filter: blur(10px);
  z-index: -1;
}

.shape.circle {
  width: 120px;
  height: 120px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  top: 15%;
  left: 10%;
}

.shape.triangle {
  width: 0;
  height: 0;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-bottom: 120px solid rgba(255, 0, 0, 0.5);
  top: 60%;
  left: 70%;
}

.shape.square {
  width: 90px;
  height: 90px;
  background: rgba(21, 255, 0, 0.6);
  top: 30%;
  left: 80%;
}
/* Float Animation */
@keyframes floatShapes {
  0% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-15px) rotate(180deg); }
  100% { transform: translateY(0) rotate(360deg); }
}

/* Gradient Animation for Background */
@keyframes vibrantGradient {
  0% { background-position: 0% 50%; }
  30% { background-position: 100% 50%; }
  60% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}


/* Content Wrapper */
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Text Content */
.text-content {
  padding: 2.5rem;
}

.welcome-title {
  font-family: 'Roboto', sans-serif;
  font-size: 3.2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.welcome-description {
  font-size: 1.6rem;
  color: #444;
  margin-bottom: 2.5rem;
}

.welcome-content {
  font-size: 1.3rem;
  color: #666;
  margin-bottom: 2.5rem;
  line-height: 1.7;
}

/* Features List */
.features-list {
  list-style: none;
  padding: 0;
  margin-bottom: 3.5rem;
}

/* Additional Details */
.features-list li {
  background: rgba(255, 255, 255, 0.1); /* Transparent white */
  transform: scale(1.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1.2rem;
  border-radius: 15px;
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.features-list li:hover {
  background: rgba(0, 0, 0, 0.3);
  transform: scale(1.55);
}
/* Button Container */
.welcome-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.public-listings-button {
  margin-top: 1.5rem;
}

.button-group {
  display: flex;
  gap: 2.5rem;
}

/* Button Styling */
.btn {
  display: inline-block;
  padding: 0.85rem 2.5rem;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary {
  background-color: #FE3C01; /* Orange color */
  box-shadow: none;
}

.btn-secondary {
  background-color: #34495e; /* Dark green/black */
  box-shadow: none;
}

.btn-primary:hover {
  transform: translateY(-6px);
  background-color: #d35400;
}

.btn-secondary:hover {
  transform: translateY(-6px);
  background-color: #2c3e50;
}

/* Additional Button Styling for Consistency */
.btn-tertiary {
  background-color: #76b852; /* Green color */
  box-shadow: 0 8px 15px rgba(118, 184, 82, 0.3);
}

.btn-tertiary:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 12px 20px rgba(118, 184, 82, 0.5);
  background-color: #82e075;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .welcome-container {
    padding: 2rem;
    border-radius: 10px;
  }

  .language-selector {
    text-align: center;
  }

  .text-content {
    padding: 1.5rem;
  }

  .welcome-title {
    font-size: 2.5rem;
  }

  .welcome-description {
    font-size: 1.4rem;
  }

  .welcome-content {
    font-size: 1.1rem;
  }

  .features-list li {
    font-size: 1rem;
  }

  .button-group {
    flex-direction: column;
    gap: 1.5rem;
  }

  .btn {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .welcome-container {
    padding: 1.5rem;
  }

  .welcome-title {
    font-size: 2rem;
  }

  .welcome-description {
    font-size: 1.2rem;
  }

  .welcome-content {
    font-size: 1rem;
  }

  .features-list li {
    font-size: 0.9rem;
    padding: 1rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 0.7rem 1.5rem;
    max-width: 250px;
  }
}
/* Additional Swimming Triangles */
.floating-shapes .triangle-swim {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 70px solid rgba(0, 247, 255, 0.7);
  animation: swimTriangle 15s infinite ease-in-out;
  z-index: -1;
  opacity: 0.25;
  filter: blur(6px);
}

.triangle-swim:nth-child(1) {
  top: 10%;
  left: 20%;
  animation-duration: 12s;
  animation-delay: 0s;
}

.triangle-swim:nth-child(2) {
  top: 30%;
  left: 70%;
  animation-duration: 10s;
  animation-delay: 2s;
}

.triangle-swim:nth-child(3) {
  top: 50%;
  left: 40%;
  animation-duration: 14s;
  animation-delay: 1s;
}

.triangle-swim:nth-child(4) {
  top: 70%;
  left: 15%;
  animation-duration: 16s;
  animation-delay: 3s;
}

.triangle-swim:nth-child(5) {
  top: 85%;
  left: 60%;
  animation-duration: 18s;
  animation-delay: 4s;
}

/* Swimming Animation for Triangles */
@keyframes swimTriangle {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(50px, -30px) rotate(90deg);
  }
  50% {
    transform: translate(-50px, 30px) rotate(180deg);
  }
  75% {
    transform: translate(30px, -50px) rotate(270deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}
/* Additional Swimming Circles */
.floating-shapes .circle-swim {
  position: absolute;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: swimCircle 20s infinite ease-in-out;
  z-index: -1;
  opacity: 0.3;
  filter: blur(8px);
}

.circle-swim:nth-child(1) {
  top: 5%;
  left: 25%;
  animation-duration: 18s;
  animation-delay: 1s;
}

.circle-swim:nth-child(2) {
  top: 20%;
  left: 50%;
  animation-duration: 22s;
  animation-delay: 2s;
}

.circle-swim:nth-child(3) {
  top: 40%;
  left: 75%;
  animation-duration: 16s;
  animation-delay: 0.5s;
}

.circle-swim:nth-child(4) {
  top: 65%;
  left: 30%;
  animation-duration: 20s;
  animation-delay: 3s;
}

.circle-swim:nth-child(5) {
  top: 80%;
  left: 10%;
  animation-duration: 25s;
  animation-delay: 1.5s;
}

/* Additional Swimming Squares */
.floating-shapes .square-swim {
  position: absolute;
  width: 60px;
  height: 60px;
  background: rgba(21, 255, 0, 0.6);
  animation: swimSquare 18s infinite ease-in-out;
  z-index: -1;
  opacity: 0.25;
  filter: blur(8px);
}

.square-swim:nth-child(1) {
  top: 10%;
  left: 40%;
  animation-duration: 14s;
  animation-delay: 0s;
}

.square-swim:nth-child(2) {
  top: 35%;
  left: 60%;
  animation-duration: 20s;
  animation-delay: 1s;
}

.square-swim:nth-child(3) {
  top: 55%;
  left: 80%;
  animation-duration: 22s;
  animation-delay: 3s;
}

.square-swim:nth-child(4) {
  top: 70%;
  left: 15%;
  animation-duration: 17s;
  animation-delay: 2s;
}

.square-swim:nth-child(5) {
  top: 90%;
  left: 50%;
  animation-duration: 19s;
  animation-delay: 1.5s;
}

/* Swimming Animation for Circles */
@keyframes swimCircle {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(-30px, 50px) scale(1.2);
  }
  50% {
    transform: translate(50px, -30px) scale(1);
  }
  75% {
    transform: translate(-50px, -50px) scale(0.8);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

/* Swimming Animation for Squares */
@keyframes swimSquare {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(30px, -30px) rotate(45deg);
  }
  50% {
    transform: translate(-30px, 30px) rotate(90deg);
  }
  75% {
    transform: translate(50px, -50px) rotate(135deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}