.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .contact-description {
    text-align: center;
    margin-bottom: 2rem;
    color: #555;
    font-size: 1.1rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 1.5rem;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: #3498db;
  }
  
  .contact-form textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  .contact-button {
    padding: 0.75rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .contact-button:hover {
    background-color: #217dbb;
  }
  