.custom-navbar {
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .navbar-brand {
    background-color: transparent; /* Makes background transparent */
    border: none; /* Removes the border */
    padding: 0; /* Removes any extra padding */
    margin: 0; /* Removes any extra margin */
    outline: none; /* Removes focus outline */
    cursor: pointer; /* Changes cursor to pointer */
    display: flex; /* Aligns the content inside */
    align-items: center; /* Vertically aligns logo and text */
  }
  
  .logo {
    height: 50px;
    max-height: 3rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .navbar-brand:hover .logo {
    transform: scale(1.1);
    transition: transform 0.3s ease; /* Smooth enlargement */

  }
  .brand-name {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem; /* Match the logo's height */
    font-weight: bold;
    color: #333;
    transition: color 0.3s ease-in-out;
  }
  
  .brand-name .highlight {
    color: #FE3C01;
  }
  .navbar-brand:hover .brand-name {
    color: #FE3C01;
  }
  .nav-link, .dropdown-item {
    color: #555;
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover, .dropdown-item:hover {
    color: #FE3C01;
  }
  
  .icon {
    margin-right: 5px;
    font-size: 1.1rem;
  }
  
  .active-link {
    font-weight: bold;
    color: #FE3C01 !important;
    border-bottom: 2px solid #FE3C01;
  }
  
  .dropdown-menu {
    min-width: 160px;
    padding: 8px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  
  .navbar-toggler {
    border: none;
  }
  
  @media (max-width: 768px) {
    .nav-link, .dropdown-item {
      font-size: 1rem;
    }
  }
  