/* General container styling */
.container {
    margin-top: 20px;
    padding: 20px;
  }
  
  /* Card styling for applied listings */
  .listing-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .listing-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Image styling */
  .listing-card .card-img-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    height: 180px; /* Adjust image height for consistency */
  }
  
  /* Card body */
  .listing-card .card-body {
    padding: 15px;
  }
  
  .listing-card .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
  }
  
  .listing-card .card-text {
    margin-bottom: 0.75rem;
  }
  
  .listing-card .btn-primary {
    background-color: #FE3C01;
    border-color: #FE3C01;
    color: white;
  }
  
  .listing-card .btn-primary:hover {
    background-color: #d35400;
    border-color: #d35400;
  }
  
  .text-center {
    text-align: center;
  }
  
  /* Spinner styling for loading */
  .spinner-border {
    width: 3rem;
    height: 3rem;
    border-width: 0.3rem;
    color: #FE3C01;
    margin-top: 20px;
  }
  /* Container for image with floating logo */
.image-container {
  position: relative;
}

.card-img-top {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
  height: 180px; /* Consistent height */
}

/* Floating logo overlay */
.company-logo-overlay {
  position: absolute;
  top: 8px; /* Adjust the position as needed */
  right: 8px;
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8); /* Optional background */
  padding: 2px;
}
