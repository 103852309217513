/* Password Container */
.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: 100%;
  padding-right: 40px; /* Space for the eye icon */
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888; /* Subtle color for the icon */
}

.password-toggle:hover {
  color: #333; /* Darker color on hover */
}

.password-toggle svg {
  width: 20px;
  height: 20px;
}
.gdpr-link {
  text-decoration: underline;
  color: inherit;
}
.gdpr-link:hover {
  color: #b84a00; /* Optional: Slightly darker shade on hover */
}
.input-group-prepend {
  width: 35%; /* Adjust percentage to control size */
}

.phone-number-input {
  flex: 1; /* Let the phone number field take the remaining space */
}
