/* General Container */
.container {
  margin-top: 90px;
  padding: 20px;
}

/* Listing Card Styling */
.listing-card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  margin: 1rem 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  background-color: #fff;
}

.listing-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

/* Right-aligned Image Column */
.listing-image {
  flex-shrink: 0;
  width: 150px; /* Adjust width to make it smaller */
  height: auto;
  margin-left: 1rem;
}

.listing-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid #ddd;
}

/* Button Styling */
.apply-btn {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  background-color: #FE3C01;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-btn:hover {
  background-color: #d35400;
}

.apply-btn.disabled-btn {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Logo and Title */
.listing-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.company-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.75rem;
}

/* Title Styling */
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin: 0 0 0.5rem;
}

.card-text {
  font-size: 0.9rem;
  color: #555;
  margin: 0.3rem 0;
}

.card-text strong {
  color: #d35400;
}

/* Price, Size, Rooms Row */
.listing-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid #f0f0f0;
}

/* Details Column */
.listing-details {
  flex: 1;
  padding-right: 1rem;
}

.listing-info-item {
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

.listing-info-item span {
  color: #777;
  font-weight: normal;
}

/* Buttons */
.btn-link {
  color: #FE3C01 !important;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s ease;
}

.btn-link:hover {
  color: #d35400 !important;
}

.btn-secondary {
  background-color: #34495e;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.btn-secondary:hover {
  background-color: #2c3e50;
}

/* Applied Count */
.applied-count {
  font-size: 0.9rem;
  color: #888;
  font-style: italic;
  margin-top: 0.5rem;
}

/* Styling for the Details Icon */
.details-icon {
  font-size: 1rem;
  color: #FE3C01;
  transition: color 0.3s ease;
  margin-left: 5px;
  cursor: pointer;
}

.details-icon:hover {
  color: #d35400;
}

/* Error and Loading Styling */
.error, .no-listings {
  background-color: #fddede;
  color: #d8000c;
  border: 1px solid #d8000c;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  margin: 10px 0;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.3rem;
  color: #FE3C01;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .listing-card {
      flex-direction: column;
      padding: 1rem;
      align-items: flex-start;
  }

  .listing-header {
      flex-direction: column;
      align-items: flex-start;
  }

  .company-logo {
      width: 35px;
      height: 35px;
      margin-bottom: 0.5rem;
  }
  .listing-image {
    width: 100%; /* Full width on smaller screens */
    max-width: 120px; /* Optional: limit max width for very small screens */
  }

  .card-title {
      font-size: 1.1rem;
  }

  .listing-image {
      width: 100%;
      margin: 0;
      margin-top: 1rem;
  }

  .apply-btn {
      width: 100%;
      font-size: 0.9rem;
      padding: 8px 0;
  }
  
  .listing-info {
      flex-direction: column;
      align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .listing-card {
      padding: 0.75rem;
      margin: 1rem 0; /* Adds space between listings */
  }

  .company-logo {
      width: 30px;
      height: 30px;
  }

  .card-title {
      font-size: 1rem;
  }

  .listing-image {
      width: 100%;
      max-width: 80px;
      margin-bottom: 1rem; /* Space below the image */
  }

  .card-text {
      font-size: 0.85rem;
  }

  .apply-btn {
      font-size: 0.8rem;
      padding: 6px 0;
  }
  
  .listing-info-item {
      font-size: 0.85rem;
  }

  .details-icon {
      font-size: 0.9rem;
  }

  /* Additional Spacing Between Listings */
  .row > .col-12 {
      margin-bottom: 1rem;
  }
}
/* Image Container for Mobile Mode */
.image-container {
  position: relative;
}

/* Floating Logo Overlay */
.company-logo-overlay {
  position: absolute;
  top: 8px; /* Adjusts the top margin */
  right: 8px; /* Adjusts the right margin */
  width: 35px; /* Logo size */
  height: 35px;
  border-radius: 50%; /* Makes the logo circular */
  background-color: rgba(255, 255, 255, 0.8); /* Optional white background with transparency */
  padding: 2px; /* Optional padding around logo */
}
