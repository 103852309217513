/* Location Toggle Styling */
.location-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    background-color: #34495e;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
  }
  
  .arrow-up {
    border: solid #fff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .arrow-down {
    border: solid #fff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  /* Checkbox Styling */
  .location-checkboxes {
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-check {
    margin-bottom: 5px;
  }
  
  .btn-primary {
    background-color: #FE3C01;
    border-color: #FE3C01;
    color: white;
  }
  
  .btn-primary:hover {
    background-color: #d35400;
    border-color: #d35400;
  }
  
  .btn-secondary {
    background-color: #34495e;
    border-color: #34495e;
    color: #fff;
  }
  
  .btn-secondary:hover {
    background-color: #2c3e50;
    border-color: #2c3e50;
  }
  