/* Reset CSS */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  min-height: 100%;
}

/* Main App Styling */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content Container */
.container {
  padding: 20px;
  margin-top: 90px; /* Avoid overlap with fixed navbar */
}
.container h2 {
  font-size: 2.4rem;
  color: #2c3e50;
  font-weight: 700;
  margin-bottom: 1.5rem;
  border-left: 6px solid #FE3C01;
  padding-left: 10px;
}
.btn-danger-custom {
  background-color: #e74c3c; /* Bootstrap-like red */
  color: #fff;
  border: none;
  margin-top: 1rem;
  padding: 0.7rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn-danger-custom:hover {
  background-color: #c0392b; /* Darker red on hover */
}

/* Profile Container */
.profile-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
  margin-top: 2rem;
}
.profile-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Form Styling */
.form-group {
  margin-bottom: 1.2rem;
}
.form-group label {
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}
.form-control {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.6rem;
}
.form-control:focus {
  border-color: #FE3C01;
  box-shadow: 0 0 5px rgba(243, 156, 18, 0.5);
}

/* Button Styling */
.btn-primary, .btn-secondary {
  margin-top: 1rem;
  padding: 0.7rem 1.5rem;
  border-radius: 5px;
}
.btn-primary {
  background-color: #FE3C01;
  border: none;
}
.btn-primary:hover {
  background-color: #FE3C01;
}
.btn-secondary {
  background-color: #34495e;
  color: #fff;
  border: none;
}
.btn-secondary:hover {
  background-color: #2c3e50;
}

/* Link Button Styling */
.btn-link {
  color: #FE3C01 !important;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.btn-link:hover {
  color: #d35400 !important;
}

/* Radio Button Group */
.radio-group {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.radio-group label {
  margin: 0;
}
.radio-group input {
  margin-right: 0.5rem;
}

/* Alert and Validation Feedback */
.alert {
  margin-bottom: 1.5rem;
  padding: 0.75rem 1rem;
  border-radius: 5px;
}
.alert-success {
  background-color: #dff0d8;
  color: #3c763d;
}
.alert-danger {
  background-color: #f2dede;
  color: #a94442;
}
.alert-info {
  background-color: #d9edf7;
  color: #31708f;
}
.invalid-feedback {
  color: #e74c3c;
  font-size: 0.9rem;
}

/* Notifications Container */
.notifications-container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
  margin-top: 2rem;
}
.notifications-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Form Label and Select */
.form-label {
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}
.form-select {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.6rem;
}
.form-select:focus {
  border-color: #FE3C01;
  box-shadow: 0 0 5px rgba(243, 156, 18, 0.5);
}

/* Location Toggle Button */
.location-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.arrow-up, .arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.arrow-up {
  border-bottom: 7px solid #d35400;
}
.arrow-down {
  border-top: 7px solid #d35400;
}
.location-toggle-btn:hover .arrow-up, .location-toggle-btn:hover .arrow-down {
  border-color: #FE3C01;
}
.location-checkboxes {
  margin-top: 10px;
}
.form-check-label {
  margin-left: 5px;
}
.form-check {
  margin-top: 5px;
}

/* Text Centering */
.text-center {
  text-align: center;
}

/* Spinner for Loading */
.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: 0.3rem;
  color: #1b1202;
  margin-top: 20px;
}
/* Custom spinner color within the apply button */
.apply-btn .spinner-border {
  color: #504f4e; /* Customize the color to fit your brand */
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .container h2 {
    font-size: 2rem;
    padding-left: 8px;
  }
  .btn-danger-custom {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .profile-container, .notifications-container {
    padding: 1.5rem;
    margin-top: 1.5rem;
  }

  .radio-group {
    flex-direction: column;
  }

  .btn-primary, .btn-secondary {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
    margin-top: 60px;
  }
  .btn-danger-custom {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .profile-container, .notifications-container {
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 8px;
  }

  .container h2 {
    font-size: 1.8rem;
  }

  .form-group label, .form-label {
    font-size: 1rem;
  }

  .btn-primary, .btn-secondary {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .spinner-border {
    width: 2rem;
    height: 2rem;
  }
}
/* Modal Button and Text Styling */
@media (max-width: 576px) {
  .modal-footer .btn {
    width: 100%; /* Make buttons full-width on small screens */
    margin-bottom: 0.5rem;
  }
  .modal-body p, .modal-body a {
    font-size: 0.9rem; /* Reduce font size for better readability */
  }
}
.discount-admin-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.add-discount-form {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.discount-table {
  margin-top: 20px;
}

.discount-table th,
.discount-table td {
  text-align: center;
}
.balances {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.balance-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.balance-icon {
  font-size: 1.5em;
  color: #007bff;
}
