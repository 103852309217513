.footer {
  background-color: #343a40;
  color: #f8f9fa;
  padding: 1px 0;
  border-top: 1px solid #555;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2px;
}


.footer h5 {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer p {
  margin: 0.5rem 0;
  line-height: 1.6;
}

.footer a {
  color: #f8f9fa;
  text-decoration: none;
}

.footer a:hover {
  color: #d35400;
  text-decoration: underline;
}

.footer .social-icons {
  margin-top: 1rem;
}

.footer .social-icons a {
  font-size: 1.5rem;
  margin-right: 15px;
}

.footer .social-icons a:hover {
  color: #d35400;
}

/* Map Styling */
.footer-map {
  width: 100%;
  max-width: 150px;
  margin-top: 0px;
  border-radius: 8px;
}

/* Ensuring Map is Right-Aligned */
.col-map {
  display: flex;
  justify-content: flex-end;
}

/* Responsive Design for Smaller Devices */
@media (max-width: 768px) {
  .footer .col-md-3 {
    text-align: center;
    align-items: center; /* Centers content horizontally */
    margin-bottom: 1.5rem;
  }

  .footer .social-icons {
    margin-top: 0.5rem;
  }

  .footer-map {
    margin: 1rem auto 0;
  }
}
